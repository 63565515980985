import useTranslation from "next-translate/useTranslation";
import { useMemo } from "react";

interface UseTranslatedMetaAttributesResult {
  title?: string;
  description?: string;
  keywords?: string;
}

const useDefaultMetaAttributes = (
  pageKey?: string
): UseTranslatedMetaAttributesResult => {
  const { t } = useTranslation("meta");

  return useMemo(() => {
    if (!pageKey) {
      return {};
    }

    const titleTranslationKey = `meta:${pageKey}_title`;
    const descTranslationKey = `meta:${pageKey}_description`;
    const keywordsTranslationKey = `meta:${pageKey}_keywords`;

    const titleTranslation = t(titleTranslationKey);
    const descTranslation = t(descTranslationKey);
    const keywordsTranslation = t(keywordsTranslationKey);

    if (
      titleTranslation === titleTranslationKey ||
      descTranslation === descTranslationKey ||
      keywordsTranslation === keywordsTranslationKey
    ) {
      throw Error(
        `Missed metadata translation for page with key "${pageKey}"!`
      );
    }

    return {
      title:
        titleTranslation === titleTranslationKey ? undefined : titleTranslation,
      description:
        descTranslation === descTranslationKey ? undefined : descTranslation,
      keywords:
        keywordsTranslation === keywordsTranslationKey
          ? undefined
          : keywordsTranslation
    };
  }, [pageKey, t]);
};

export default useDefaultMetaAttributes;
