import styled from "@emotion/styled";
import NoSsr from "@mui/material/NoSsr";
import Typography from "@mui/material/Typography";
import useTranslation from "next-translate/useTranslation";

import ClaimButton from "~/components/advertisement/claim/ClaimButton";
import CleoLandingRewardProperties from "~/components/landings/vendors/CleoLanding/CleoLandingReward/CleoLandingRewardProperties";
import CleoLandingSectionTitle from "~/components/landings/vendors/CleoLanding/common/CleoLandingSectionTitle";
import { appLayoutPaddingsMixin } from "~/components/layouts/AppLayout/styled";

import CleoLandingImage from "../common/CleoLandingImage";

type Props = {
  advertisementId: string;
};

const CleoLandingReward = ({ advertisementId }: Props): JSX.Element => {
  const { t } = useTranslation("landing-cleo");

  return (
    <Wrapper>
      <Title>{t("reward_title")}</Title>
      <DiscussionDescription>
        {t("reward_description-discussion")}
      </DiscussionDescription>
      <ClaimButtonDescription>
        {t("reward_description-claim-button")}
      </ClaimButtonDescription>

      <NoSsr>
        <StyledClaimButton
          color="background.primary"
          advertisementId={advertisementId}
          claimActionLabel={t("reward_claim-button")}
        />
      </NoSsr>
      <RewardProperties />
      <ClaimImage variant="treeInTheMeadow" />
    </Wrapper>
  );
};

export default CleoLandingReward;

const Wrapper = styled.section`
  ${appLayoutPaddingsMixin};

  background-color: ${({ theme }) => theme.palette.background.octonary};

  display: grid;
  grid-template:
    "a"
    "b"
    "c"
    "d"
    "e"
    "f"
    "g";
  padding-bottom: ${({ theme }) => theme.spacing(10)};
  padding-top: ${({ theme }) => theme.spacing(18)};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding-bottom: ${({ theme }) => theme.spacing(18)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    grid-column-gap: ${({ theme }) => theme.spacing(12)};
    grid-template:
      "g a"
      "g b"
      "g c"
      "g d"
      "g e"
      "f f";
    grid-template-columns: minmax(auto, 442px) 1fr;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    grid-column-gap: ${({ theme }) => theme.spacing(8)};
    grid-template:
      "g a"
      "g b"
      "g f"
      "g c"
      "g d"
      "g e";
    grid-template-columns: minmax(auto, 620px) 1fr;
  }

  ${({ theme }) => theme.breakpoints.up("xxl")} {
    grid-column-gap: ${({ theme }) => theme.spacing(24)};
    grid-template-columns: minmax(auto, 768px) 1fr;
  }
`;

const Title = styled(CleoLandingSectionTitle)`
  grid-area: a;
  margin-top: ${({ theme }) => theme.spacing(9.5)};
`;

const Discussion = styled(Typography)``;
Discussion.defaultProps = {
  variant: "paragraphSecondary"
};

const DiscussionDescription = styled(Discussion)`
  grid-area: b;
  margin-top: ${({ theme }) => theme.spacing(5)};

  ${({ theme }) => theme.breakpoints.up("xs")} {
    margin-top: ${({ theme }) => theme.spacing(8)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: ${({ theme }) => theme.spacing(5)};
  }
`;

const ClaimButtonDescription = styled(Discussion)`
  grid-area: c;
  margin-top: ${({ theme }) => theme.spacing(5)};

  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-top: ${({ theme }) => theme.spacing(6)};
  }
`;

const RewardProperties = styled(CleoLandingRewardProperties)`
  grid-area: f;
  margin-top: ${({ theme }) => theme.spacing(10)};

  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-top: ${({ theme }) => theme.spacing(6)};
  }
`;

const ClaimImage = styled(CleoLandingImage)`
  grid-area: g;
  margin-top: ${({ theme }) => theme.spacing(10)};
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 0;
  }
`;

const StyledClaimButton = styled(ClaimButton)`
  grid-area: d;
  height: 64px;
  margin-top: ${({ theme }) => theme.spacing(5)};
  min-width: 165px;

  ${({ theme }) => theme.breakpoints.up("xs")} {
    justify-self: flex-start;
  }
`;
