import styled from "@emotion/styled";
import useTranslation from "next-translate/useTranslation";

import CleoLandingHowItWorksSteps from "~/components/landings/vendors/CleoLanding/CleoLandingHowItWorks/CleoLandingHowItWorksSteps";
import CleoLandingSectionTitle from "~/components/landings/vendors/CleoLanding/common/CleoLandingSectionTitle";
import { appLayoutPaddingsMixin } from "~/components/layouts/AppLayout/styled";

const CleoLandingHowItWorks = (): JSX.Element => {
  const { t } = useTranslation("landing-cleo");

  return (
    <Wrapper>
      <InnerWrapper>
        <CleoLandingSectionTitle>
          {t("how-it-works_highlighted-title")}
        </CleoLandingSectionTitle>
        <Steps />
      </InnerWrapper>
    </Wrapper>
  );
};

export default CleoLandingHowItWorks;

const Wrapper = styled.section`
  background: #fbfbfb; /* this color is just for this block */
  width: 100%;
`;

const InnerWrapper = styled.div`
  ${appLayoutPaddingsMixin};

  padding-bottom: ${({ theme }) => theme.spacing(10)};
  padding-top: ${({ theme }) => theme.spacing(10)};

  ${({ theme }) => theme.breakpoints.up("xs")} {
    padding-bottom: ${({ theme }) => theme.spacing(10)};
    padding-top: ${({ theme }) => theme.spacing(18)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-bottom: ${({ theme }) => theme.spacing(18)};
    padding-top: ${({ theme }) => theme.spacing(18)};
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    padding-bottom: ${({ theme }) => theme.spacing(25)};
    padding-top: ${({ theme }) => theme.spacing(25)};
  }

  ${({ theme }) => theme.breakpoints.up("xxl")} {
    padding-bottom: ${({ theme }) => theme.spacing(32)};
    padding-top: ${({ theme }) => theme.spacing(25)};
  }
`;

const Steps = styled(CleoLandingHowItWorksSteps)`
  margin-top: ${({ theme }) => theme.spacing(10)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: ${({ theme }) => theme.spacing(13)};
  }
`;
