import styled from "@emotion/styled";

import CleoLandingIntroVideo from "~/components/landings/vendors/CleoLanding/CleoLandingIntro/CleoLandingIntroVideo";
import { APP_LAYOUT_GEOMETRY } from "~/components/layouts/AppLayout/constants";
import { appLayoutPaddingsMixin } from "~/components/layouts/AppLayout/styled";
import { THEME_SPACING } from "~/theme/theme";

import { CleoLandingIntroText } from "./CleoLandingIntroText";

export const BOOK_NOW_LINK = "https://calendly.com/jasoncleo/30min";

const CleoLandingIntro = (): JSX.Element => (
  <Wrapper>
    <CleoLandingIntroText />
    <CleoLandingIntroVideo />
  </Wrapper>
);

export default CleoLandingIntro;

const Wrapper = styled.section`
  ${appLayoutPaddingsMixin};

  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(8)};
  margin-top: ${({ theme }) => theme.spacing(18)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing(11)};
    min-height: 550px;
  }

  ${({ theme }) =>
    theme.breakpoints.up(
      APP_LAYOUT_GEOMETRY.maxWidth +
        APP_LAYOUT_GEOMETRY.paddings.md * 2 * THEME_SPACING
    )} {
    gap: 0;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.spacing(18)};
    padding: 0;
  }

  ${({ theme }) => theme.breakpoints.up("xxl")} {
    gap: ${({ theme }) => theme.spacing(25)};
  }
`;
