import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

import { IconColor, IconVariant } from "~/components/core/Icon/declarations";
import IconAvatar from "~/components/core/IconAvatar/IconAvatar";
import typography from "~/theme/typography";

type Props = {
  iconBackgroundColor: IconColor;
  icon: IconVariant;
  title: ReactNode;
  value: ReactNode;
  className?: string;
};

const CleoLandingRewardProperty = ({
  iconBackgroundColor,
  icon,
  title,
  value,
  className
}: Props): JSX.Element => (
  <Wrapper className={className}>
    <IconAvatar
      size={56}
      iconSize={32}
      backgroundColor={iconBackgroundColor}
      icon={icon}
      iconColor="background.octonary"
    />
    <Title>{title}</Title>
    <Value>{value}</Value>
  </Wrapper>
);

export default CleoLandingRewardProperty;

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.background.senary};
  border-radius: 24px;
  padding: ${({ theme }) => theme.spacing(6)};
`;

const Title = styled(Typography)`
  display: block;
  margin-top: ${({ theme }) => theme.spacing(6)};
`;
Title.defaultProps = {
  variant: "titleTertiary",
  color: "text.secondary"
};

const Value = styled(Typography)`
  ${typography.h5}
  margin-top: ${({ theme }) => theme.spacing(1)};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    ${typography.h4}
  }
`;
