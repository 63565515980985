import dynamic from "next/dynamic";
import { memo, useMemo } from "react";

import { VideoPlayerProps } from "~/components/core/VideoPlayer/declarations";
import { isIOS, isServerSide } from "~/utils/common";

const VideoPlayerRegular = dynamic(
  () => import("./VideoPlayerRegular/VideoPlayerRegular"),
  { ssr: false }
);
const VideoPlayerIOS = dynamic(() => import("./VideoPlayerIOS"), {
  ssr: false
});

const VideoPlayer = (props: VideoPlayerProps): JSX.Element | null => {
  const iOSDevice = useMemo(() => !isServerSide() && isIOS(), []);

  if (isServerSide()) {
    return null;
  }

  if (iOSDevice) {
    return <VideoPlayerIOS {...props} />;
  }

  return <VideoPlayerRegular {...props} />;
};

export default memo(VideoPlayer);
