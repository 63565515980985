import styled from "@emotion/styled";
import useTranslation from "next-translate/useTranslation";

import { IconColor, IconVariant } from "~/components/core/Icon/declarations";
import CleoLandingRewardProperty from "~/components/landings/vendors/CleoLanding/CleoLandingReward/CleoLandingRewardProperties/CleoLandingRewardProperty";

type Props = {
  className?: string;
};

type RewardProperty = {
  icon: IconVariant;
  iconBackgroundColor: IconColor;
  titleTranslationKey: string;
  valueTranslationKey: string;
};

const PROPERTIES: RewardProperty[] = [
  {
    icon: "calendarBicolor",
    iconBackgroundColor: "brandYellow.dark",
    titleTranslationKey: "reward_closing-date_label",
    valueTranslationKey: "reward_closing-date_value"
  },
  {
    icon: "reward",
    iconBackgroundColor: "brandBlue.main",
    titleTranslationKey: "reward_tones_label",
    valueTranslationKey: "reward_tones_value"
  },
  {
    icon: "people",
    iconBackgroundColor: "brandPurple.dark",
    titleTranslationKey: "reward_availability_label",
    valueTranslationKey: "reward_availability_value"
  }
];

const CleoLandingRewardProperties = ({ className }: Props): JSX.Element => {
  const { t } = useTranslation("landing-cleo");

  return (
    <Wrapper className={className}>
      {PROPERTIES.map(
        ({
          icon,
          iconBackgroundColor,
          titleTranslationKey,
          valueTranslationKey
        }) => (
          <Property
            key={icon}
            icon={icon}
            iconBackgroundColor={iconBackgroundColor}
            title={t(titleTranslationKey)}
            value={t(valueTranslationKey)}
          />
        )
      )}
    </Wrapper>
  );
};

export default CleoLandingRewardProperties;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};

  ${({ theme }) => theme.breakpoints.up("xs")} {
    flex-direction: row;
  }
`;

const Property = styled(CleoLandingRewardProperty)`
  ${({ theme }) => theme.breakpoints.up("xs")} {
    flex: 1;
  }
`;
