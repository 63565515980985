import styled from "@emotion/styled";
import { ReactNode } from "react";

import SubheaderBackground2x from "~/public/assets/images/backgrounds/SubheaderBackground@2x.png";

type Props = {
  className?: string;
  children: ReactNode;
};

export const APP_LAYOUT_SUBHEADER_WRAPPER_CLASSES = {
  background: "background"
};

const AppLayoutSubheaderWrapper = ({
  className,
  children
}: Props): JSX.Element => (
  <Wrapper className={className}>
    <BackgroundWrapper
      className={APP_LAYOUT_SUBHEADER_WRAPPER_CLASSES.background}
    />
    {children}
  </Wrapper>
);

export default AppLayoutSubheaderWrapper;

const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(-22)};
  padding-top: ${({ theme }) => theme.spacing(22)};
  position: relative;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin-top: ${({ theme }) => theme.spacing(-23)};
    padding-top: ${({ theme }) => theme.spacing(23)};
  }
`;

const BackgroundWrapper = styled.div`
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;

  :after {
    background-image: url(${SubheaderBackground2x.src});
    background-position: center ${({ theme }) => theme.spacing(-22)};
    background-repeat: repeat-x;
7    background-size: auto 930px;
    content: "";
    height: 956px;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;

    ${({ theme }) => theme.breakpoints.up("xs")} {
      background-position: center ${({ theme }) => theme.spacing(-30)};
      background-size: auto 900px;
      height: 732px;
    }

    ${({ theme }) => theme.breakpoints.up("sm")} {
      background-position: center ${({ theme }) => theme.spacing(-5)};
    }

    ${({ theme }) => theme.breakpoints.up("md")} {
      height: 528px;
    }

    ${({ theme }) => theme.breakpoints.up("xxl")} {
      background-size: auto 700px;
    }
  }
`;
