import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

import { IconVariant } from "~/components/core/Icon/declarations";
import IconAvatar from "~/components/core/IconAvatar/IconAvatar";

type Props = {
  icon: IconVariant;
  title: ReactNode;
  description: ReactNode;
};

const CleoLandingHowItWorksStep = ({
  icon,
  title,
  description
}: Props): JSX.Element => (
  <Wrapper>
    <IconAvatar
      icon={icon}
      iconSize={40}
      iconColor="background.octonary"
      backgroundColor="brandGreen.main"
      size={90}
    />
    <Title>{title}</Title>
    <Description>{description}</Description>
  </Wrapper>
);

export default CleoLandingHowItWorksStep;

const Wrapper = styled.div`
  background: ${({ theme }) => theme.palette.background.octonary};
  border-radius: 24px;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};
  padding: ${({ theme }) => theme.spacing(6)};

  ${({ theme }) => theme.breakpoints.up("xs")} {
    padding: ${({ theme }) => theme.spacing(8)};
  }
`;

const Title = styled(Typography)``;
Title.defaultProps = {
  variant: "h4"
};

const Description = styled(Typography)``;
Description.defaultProps = {
  variant: "paragraphSecondary",
  whiteSpace: "pre-wrap"
};
