import styled from "@emotion/styled";
import useTranslation from "next-translate/useTranslation";

import { IconVariant } from "~/components/core/Icon/declarations";

import CleoLandingHowItWorksStep from "./CleoLandingHowItWorksStep";

type Step = {
  icon: IconVariant;
  titleTranslationKey: string;
  descriptionTranslationKey: string;
};

const STEPS: Step[] = [
  {
    icon: "reward",
    titleTranslationKey: "how-it-works_step1_title",
    descriptionTranslationKey: "how-it-works_step1_description"
  },
  {
    icon: "send",
    titleTranslationKey: "how-it-works_step2_title",
    descriptionTranslationKey: "how-it-works_step2_description"
  },
  {
    icon: "3dcube",
    titleTranslationKey: "how-it-works_step3_title",
    descriptionTranslationKey: "how-it-works_step3_description"
  }
];

type Props = {
  className?: string;
};

const CleoLandingHowItWorksSteps = ({ className }: Props): JSX.Element => {
  const { t } = useTranslation("landing-cleo");

  return (
    <Wrapper className={className}>
      {STEPS.map(({ icon, descriptionTranslationKey, titleTranslationKey }) => (
        <CleoLandingHowItWorksStep
          key={icon}
          icon={icon}
          title={t(titleTranslationKey)}
          description={t(descriptionTranslationKey)}
        />
      ))}
    </Wrapper>
  );
};

export default CleoLandingHowItWorksSteps;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;
  }
`;
