/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../../../declarations/graphql/types";
import {
  ClaimRewardFragmentDoc,
  DTOClaimRewardFragment_NftPostReward_,
  DTOClaimRewardFragment_TokenPostReward_
} from "../../../graphql/claimRewardFragment.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOAdvertisementClaimStatusQueryVariables = Types.Exact<{
  id: Types.Scalars["String"];
}>;

export type DTOAdvertisementClaimStatusQuery = {
  __typename?: "Query";
  advertisement: {
    __typename: "Advertisement";
    id: string;
    status: Types.DTOPostStatus;
    isCreator: boolean;
    myReward?: { __typename: "Reward"; status: Types.DTORewardStatus } | null;
    reward?:
      | ({
          __typename?: "NftPostReward";
        } & DTOClaimRewardFragment_NftPostReward_)
      | ({
          __typename?: "TokenPostReward";
        } & DTOClaimRewardFragment_TokenPostReward_)
      | null;
    stats?: { __typename?: "PostStats"; completedCount: number } | null;
  };
};

export const AdvertisementClaimStatusQueryDocument = gql`
  query AdvertisementClaimStatusQuery($id: String!) {
    advertisement(id: $id) {
      id
      __typename
      status
      myReward {
        __typename
        status
      }
      isCreator
      reward {
        ...ClaimRewardFragment
      }
      stats {
        completedCount
      }
    }
  }
  ${ClaimRewardFragmentDoc}
`;

export function useAdvertisementClaimStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<DTOAdvertisementClaimStatusQueryVariables>,
    "query"
  >
) {
  return Urql.useQuery<
    DTOAdvertisementClaimStatusQuery,
    DTOAdvertisementClaimStatusQueryVariables
  >({ query: AdvertisementClaimStatusQueryDocument, ...options });
}
