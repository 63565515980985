import styled from "@emotion/styled";
import Button, { buttonClasses, ButtonProps } from "@mui/material/Button";
import useTranslation from "next-translate/useTranslation";

import mapDTOClaimRewardFragmentToAdvertisementReward from "~/components/advertisement/claim/adapters/mapDTOClaimRewardFragmentToAdvertisementReward";
import useClaimFlow from "~/components/advertisement/claim/useClaimFlow";
import { HasNeedToClaimCheck } from "~/components/advertisement/claim/useClaimFlow/useClaimFlow";
import { getRewardTextDescription } from "~/components/advertisement/reward/utils/getRewardTextDescription";
import Icon from "~/components/core/Icon";
import { useMe } from "~/components/person/useMe/useMe";
import { useAuthenticationContext } from "~/components/providers/AuthenticationContextProvider/AuthenticationContext";
import { AuthenticationStatus } from "~/components/providers/AuthenticationContextProvider/declarations";
import { RequestState } from "~/constants/requestState";
import { DTOPostStatus, DTORewardStatus } from "~/declarations/graphql/types";
import { ColorsFromPalette } from "~/theme/colorsFromPalette";
import assertUnreachable from "~/utils/assertUnreachable";
import { isNotNullable } from "~/utils/common";
import useGraphQLQuery from "~/utils/graphql/useGraphQLQuery/useGraphQLQuery";
import {
  backgroundFromPaletteMixin,
  iconColorFromPaletteMixin
} from "~/utils/style";

import {
  DTOAdvertisementClaimStatusQuery,
  DTOAdvertisementClaimStatusQueryVariables,
  useAdvertisementClaimStatusQuery
} from "./graphql/advertisementClaimStatusQuery.generated";

const DEFAULT_BUTTON_PROPS: ButtonProps = {
  variant: "contained",
  size: "large"
};

type Props = {
  className?: string;
  advertisementId: string;
  claimActionLabel: string;
  color: ColorsFromPalette;
  hasNeedToClaim?: HasNeedToClaimCheck;
};

const ClaimButton = ({
  advertisementId,
  claimActionLabel,
  className,
  color,
  hasNeedToClaim
}: Props): JSX.Element | null => {
  const { t } = useTranslation("common");
  const { fetching, handleClaim } = useClaimFlow();
  const { authenticationStatus } = useAuthenticationContext();

  const meResult = useMe();
  const advertisementClaimStatusResult = useGraphQLQuery<
    DTOAdvertisementClaimStatusQuery,
    "advertisement",
    undefined,
    DTOAdvertisementClaimStatusQueryVariables
  >({
    queryKey: "advertisement",
    useQuery: useAdvertisementClaimStatusQuery,
    options: {
      variables: { id: advertisementId }
    }
  });

  const handleClickClaim = (): Promise<void> =>
    handleClaim(advertisementId, hasNeedToClaim);

  const disabled =
    fetching ||
    advertisementClaimStatusResult.type !== RequestState.Success ||
    isNotNullable(advertisementClaimStatusResult.data.myReward) ||
    ((meResult.type !== RequestState.Success || meResult.data.isCreator) &&
      authenticationStatus !== AuthenticationStatus.unauthenticated);

  const claimAction = (
    <ClaimRewardButton
      disabled={disabled}
      onClick={handleClickClaim}
      background={color}
      className={className}
    >
      {claimActionLabel}
    </ClaimRewardButton>
  );

  if (authenticationStatus === AuthenticationStatus.unauthenticated) {
    return claimAction;
  }

  if (
    advertisementClaimStatusResult.type !== RequestState.Success ||
    meResult.type !== RequestState.Success
  ) {
    return null;
  }

  const advertisement = advertisementClaimStatusResult.data;

  if (
    advertisement.status === DTOPostStatus.Completed &&
    !advertisement.myReward
  ) {
    return (
      <CompletedStatus className={className}>
        {t("advertisement_completed_status")}
      </CompletedStatus>
    );
  }

  if (advertisement.isCreator) {
    const completedTaskCount = advertisement.stats?.completedCount ?? 0;

    return (
      <ButtonWithStatistics
        iconColor={color}
        startIcon={<Icon variant="check" size={20} />}
        className={className}
      >
        {t("advertisement_completed-tasks_label", {
          count: completedTaskCount
        })}
      </ButtonWithStatistics>
    );
  }

  const rewardStatus = advertisement.myReward?.status;

  switch (rewardStatus) {
    case DTORewardStatus.Pending: {
      return (
        <PendingStatus background={color} className={className}>
          {t("advertisement_pending_status")}
        </PendingStatus>
      );
    }
    case DTORewardStatus.Approved:
    case DTORewardStatus.ApprovedWithBonus: {
      const reward = mapDTOClaimRewardFragmentToAdvertisementReward(
        advertisement.reward
      );

      return (
        <ButtonWithStatistics
          iconColor={color}
          className={className}
          startIcon={<Icon variant="reward" size={20} />}
        >
          {t("advertisement_reward-claimed_label", {
            reward: getRewardTextDescription(reward)
          })}
        </ButtonWithStatistics>
      );
    }
    case undefined: {
      return claimAction;
    }
  }

  assertUnreachable(rewardStatus);
  return null;
};

export default ClaimButton;

const StyledButton = styled(Button)``;
StyledButton.defaultProps = {
  ...DEFAULT_BUTTON_PROPS
};

const ClaimRewardButton = styled(StyledButton)`
  ${backgroundFromPaletteMixin}
`;

const PendingStatus = styled(StyledButton)`
  &.${buttonClasses.disabled} {
    ${backgroundFromPaletteMixin}
    color: ${({ theme }) => theme.palette.text.quaternary};
  }
`;
PendingStatus.defaultProps = {
  ...DEFAULT_BUTTON_PROPS,
  startIcon: <Icon variant="pending" size={20} />,
  disabled: true
};

const CompletedStatus = styled(StyledButton)`
  &.${buttonClasses.disabled} {
    background: ${({ theme }) => theme.palette.transparent.inverse[4]};
    color: ${({ theme }) => theme.palette.text.tertiary};

    .${buttonClasses.startIcon} svg {
      --color-primary: ${({ theme }) => theme.palette.text.primary};
    }
  }
`;
CompletedStatus.defaultProps = {
  ...DEFAULT_BUTTON_PROPS,
  startIcon: <Icon variant="check" color="text.primary" />,
  disabled: true
};

const ButtonWithStatistics = styled(StyledButton)`
  & {
    height: auto;
  }

  &.${buttonClasses.disabled} {
    ${iconColorFromPaletteMixin}
    background: transparent;
    border: 1px solid ${({ theme }) => theme.palette.transparent.inverse[4]};
  }

  & .${buttonClasses.startIcon} svg {
    ${iconColorFromPaletteMixin}
  }
`;
ButtonWithStatistics.defaultProps = {
  ...DEFAULT_BUTTON_PROPS,
  disabled: true
};
