import { css } from "@emotion/react";

import { APP_LAYOUT_GEOMETRY } from "~/components/layouts/AppLayout/constants";
import { StyledMixin } from "~/declarations/styled";
import { THEME_SPACING } from "~/theme/theme";

export const appLayoutPaddingsMixin: StyledMixin = ({ theme }) => css`
  max-width: ${APP_LAYOUT_GEOMETRY.maxWidth}px;

  padding-left: ${theme.spacing(APP_LAYOUT_GEOMETRY.paddings.xxs)};
  padding-right: ${theme.spacing(APP_LAYOUT_GEOMETRY.paddings.xxs)};

  ${theme.breakpoints.up("sm")} {
    padding-left: ${theme.spacing(APP_LAYOUT_GEOMETRY.paddings.sm)};
    padding-right: ${theme.spacing(APP_LAYOUT_GEOMETRY.paddings.sm)};
  }

  ${theme.breakpoints.up("md")} {
    padding-left: ${theme.spacing(APP_LAYOUT_GEOMETRY.paddings.md)};
    padding-right: ${theme.spacing(APP_LAYOUT_GEOMETRY.paddings.md)};
  }

  ${theme.breakpoints.up(
    APP_LAYOUT_GEOMETRY.maxWidth +
      APP_LAYOUT_GEOMETRY.paddings.md * 2 * THEME_SPACING
  )} {
    margin-left: auto;
    margin-right: auto;
    max-width: ${APP_LAYOUT_GEOMETRY.maxWidth}px;
    padding-left: 0;
    padding-right: 0;
  }
`;
