import styled from "@emotion/styled";

import AppLayoutSubheaderWrapper, {
  APP_LAYOUT_SUBHEADER_WRAPPER_CLASSES
} from "~/components/layouts/AppLayout/AppLayoutSubheaderWrapper";

import CleoLandingBenefits from "./CleoLandingBenefits";
import CleoLandingHowItWorks from "./CleoLandingHowItWorks";
import CleoLandingIntro from "./CleoLandingIntro";
import CleoLandingReward from "./CleoLandingReward";

type Props = {
  advertisementId: string;
};

const CleoLanding = ({ advertisementId }: Props): JSX.Element => (
  <StyledAppLayoutSubheaderWrapper>
    <CleoLandingIntro />
    <CleoLandingReward advertisementId={advertisementId} />
    <CleoLandingHowItWorks />
    <CleoLandingBenefits />
  </StyledAppLayoutSubheaderWrapper>
);

export default CleoLanding;

const StyledAppLayoutSubheaderWrapper = styled(AppLayoutSubheaderWrapper)`
  .${APP_LAYOUT_SUBHEADER_WRAPPER_CLASSES.background} {
    ::after {
      ${({ theme }) => theme.breakpoints.up("md")} {
        height: 754px;
      }
    }
  }
`;
