import styled from "@emotion/styled";
import get from "lodash.get";
import { forwardRef } from "react";

import Icon from "~/components/core/Icon";
import {
  IconColor,
  IconSize,
  IconVariant
} from "~/components/core/Icon/declarations";
import { ColorsFromPalette } from "~/theme/colorsFromPalette";

type Props = {
  backgroundColor: ColorsFromPalette;
  icon: IconVariant;
  iconColor: IconColor;
  iconSize: IconSize;
  size: number;
  className?: string;
};

const IconAvatar = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { backgroundColor, icon, iconColor, iconSize, size, className } = props;

  return (
    <Wrapper
      ref={ref}
      backgroundColor={backgroundColor}
      size={size}
      className={className}
    >
      <Icon variant={icon} size={iconSize} color={iconColor} />
    </Wrapper>
  );
});

const Wrapper = styled("div", {
  shouldForwardProp: prop =>
    !["size", "backgroundColor"].includes(prop.toString())
})<{
  backgroundColor: ColorsFromPalette;
  size: number;
}>`
  align-items: center;
  background-color: ${({ theme, backgroundColor }) =>
    get(theme.palette, backgroundColor)};
  border-radius: 50%;
  display: flex;
  height: ${({ size }) => size}px;
  justify-content: center;
  width: ${({ size }) => size}px;
`;

export default IconAvatar;
