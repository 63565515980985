import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

import typography from "~/theme/typography";

type Props = {
  className?: string;
  children?: ReactNode;
};

const CleoLandingSectionTitle = ({
  className,
  children
}: Props): JSX.Element => <Wrapper className={className}>{children}</Wrapper>;

export default CleoLandingSectionTitle;

const Wrapper = styled(Typography)`
  ${typography.h3};

  ${({ theme }) => theme.breakpoints.up("md")} {
    ${typography.h2};
  }
`;
