import styled from "@emotion/styled";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useTranslation from "next-translate/useTranslation";

import typography from "~/theme/typography";

const BOOK_NOW_LINK = "https://calendly.com/jasoncleo/30min";

export const CleoLandingIntroText = (): JSX.Element => {
  const { t } = useTranslation("landing-cleo");

  return (
    <TextIntroWrapper>
      <Title>{t("intro_title")}</Title>
      <DescriptionWrapper>
        <Description>{t("intro_description_what-we-can-offer")}</Description>
      </DescriptionWrapper>
      <BookNowButton href={BOOK_NOW_LINK}>{t("intro_book-now")}</BookNowButton>
    </TextIntroWrapper>
  );
};

export const TextIntroWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(5)};
  text-align: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 448px;
    text-align: left;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    max-width: 668px;
  }
`;

export const Title = styled(Typography)`
  ${typography.h4}
  margin: 0 auto;

  ${({ theme }) => theme.breakpoints.up("xs")} {
    ${typography.h3}
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: unset;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    ${typography.h3}
    margin: 0;
    max-width: 448px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    ${typography.h2}
    max-width: 680px;
  }
`;
Title.defaultProps = {
  whiteSpace: "pre-wrap"
};

const DescriptionWrapper = styled.div``;

export const Description = styled(Typography)`
  display: flex;
`;
Description.defaultProps = {
  variant: "paragraphSecondary",
  whiteSpace: "pre-wrap"
};

export const BookNowButton = styled(Button)`
  ${({ theme }) => theme.breakpoints.up("xs")} {
    margin: 0 auto;
    max-width: 165px;
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin: unset;
  }
`;
BookNowButton.defaultProps = {
  variant: "contained",
  size: "large",
  color: "brandGreen",
  target: "_blank"
  /* Incorrect mui typings */
} as ButtonProps;
