import {
  VendorSpecific,
  VendorToAdvertisementMap
} from "~/components/landings/declarations";
import { isString } from "~/utils/common";

export const parseVendorSpecific = (): VendorSpecific => {
  const rawValue = JSON.parse(
    process.env.NEXT_PUBLIC_LANDING_SPECIFIC_MAP ?? ""
  );

  if (!rawValue || typeof rawValue !== "object") {
    {
      throw new Error("Incorrect format of NEXT_PUBLIC_LANDING_SPECIFIC_MAP");
    }
  }

  return rawValue;
};

export const parseVendorToAdvertisementMap = (): VendorToAdvertisementMap => {
  const rawValue = JSON.parse(
    process.env.NEXT_PUBLIC_LANDING_TO_ADVERTISEMENT_MAP ?? ""
  );

  if (
    !rawValue ||
    typeof rawValue !== "object" ||
    !Object.keys(rawValue).every(key => isString(rawValue[key]))
  ) {
    {
      throw new Error(
        "Incorrect format of NEXT_PUBLIC_LANDING_TO_ADVERTISEMENT_MAP"
      );
    }
  }

  return rawValue;
};
