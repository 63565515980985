import styled from "@emotion/styled";

import TreeInTheMeadow from "~/public/assets/images/landings/Cleo/TreeInTheMeadow.jpg";
import TreeOnTheRock from "~/public/assets/images/landings/Cleo/TreeOnTheRock.jpg";

const IMAGE_SRC_BY_VARIANT = {
  treeInTheMeadow: TreeInTheMeadow.src,
  treeOnTheRock: TreeOnTheRock.src
};

type Props = {
  variant: "treeOnTheRock" | "treeInTheMeadow";
  className?: string;
};

const CleoLandingImage = ({ variant, className }: Props): JSX.Element => (
  <Image src={IMAGE_SRC_BY_VARIANT[variant]} className={className} />
);

export default CleoLandingImage;

const Image = styled.img`
  aspect-ratio: 1;
  border-radius: 40px;
  display: block;
`;
