import styled from "@emotion/styled";

import VideoPlayer from "~/components/core/VideoPlayer";
import { VENDOR_SPECIFIC } from "~/components/landings/vendorSpecific";

import { CleoSpecificPayload } from "../declarations";

const INTRO_VIDEO_SRC =
  (VENDOR_SPECIFIC?.Cleo as unknown as CleoSpecificPayload)?.videoUrl ?? "";

const CleoLandingIntroVideo = (): JSX.Element => (
  <>
    <Wrapper>
      <Screen>
        <Header>
          <LeftButton />
          <MiddleButton />
          <RightButton />
        </Header>
        <PreviewWrapper>
          <VideoPlayer
            mode="preview"
            src={INTRO_VIDEO_SRC}
            autoPlay={false}
            disableCountdown
          />
        </PreviewWrapper>
      </Screen>
    </Wrapper>
  </>
);

export default CleoLandingIntroVideo;

const Wrapper = styled.section`
  flex: 1;
  max-width: 884px;
  min-width: 0;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-bottom: ${({ theme }) => theme.spacing(20)};
  }
`;

const Screen = styled.div`
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  ${({ theme }) => theme.breakpoints.up("xs")} {
    border-radius: 12px;
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    border-radius: 20px;
  }
`;

const Header = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.palette.brandGreen.main};
  display: flex;
  gap: ${({ theme }) => theme.spacing(0.5)};
  height: ${({ theme }) => theme.spacing(2.5)};
  padding: ${({ theme }) => theme.spacing(0, 1)};

  ${({ theme }) => theme.breakpoints.up("xs")} {
    height: ${({ theme }) => theme.spacing(4)};
    gap: ${({ theme }) => theme.spacing(1)};
    padding: ${({ theme }) => theme.spacing(0, 2.5)};
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    height: ${({ theme }) => theme.spacing(5)};
    padding: ${({ theme }) => theme.spacing(0, 3)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    height: ${({ theme }) => theme.spacing(6)};
    padding: ${({ theme }) => theme.spacing(0, 4)};
  }
`;

const HeaderButton = styled.span`
  border-radius: 50%;
  height: ${({ theme }) => theme.spacing(0.5)};
  width: ${({ theme }) => theme.spacing(0.5)};

  ${({ theme }) => theme.breakpoints.up("xs")} {
    height: ${({ theme }) => theme.spacing(1)};
    width: ${({ theme }) => theme.spacing(1)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    height: ${({ theme }) => theme.spacing(1.5)};
    width: ${({ theme }) => theme.spacing(1.5)};
  }
`;

const LeftButton = styled(HeaderButton)`
  background: #ff5f52;
`;

const MiddleButton = styled(HeaderButton)`
  background: #fece01;
`;

const RightButton = styled(HeaderButton)`
  background: ${({ theme }) => theme.palette.static.background.light.octonary};
`;

const PreviewWrapper = styled.div`
  aspect-ratio: 16 / 9;
  background: none;
  border: solid ${({ theme }) => theme.palette.brandGreen.light};
  border-width: 0 1px 1px 1px;
  cursor: pointer;
  overflow: hidden;
  padding: 0;
  position: relative;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    border-width: 0 2px 2px 2px;
  }
`;
