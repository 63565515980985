import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useTranslation from "next-translate/useTranslation";

import WithLink from "~/components/common/WithLink";
import CleoLandingSectionTitle from "~/components/landings/vendors/CleoLanding/common/CleoLandingSectionTitle";
import { appLayoutPaddingsMixin } from "~/components/layouts/AppLayout/styled";

import CleoLandingImage from "../common/CleoLandingImage";

const CleoLandingBenefits = (): JSX.Element => {
  const { t } = useTranslation("landing-cleo");

  return (
    <Wrapper>
      <Content>
        <CleoLandingSectionTitle>{t("benefits_title")}</CleoLandingSectionTitle>
        <Text>{t("benefits_text")}</Text>
        <WithLink href={`mailto:${process.env.NEXT_PUBLIC_SUPPORT_EMAIL}`}>
          <ContactUsButton size="extraLarge">
            {t("benefits_contact-us")}
          </ContactUsButton>
        </WithLink>
      </Content>
      <BenefitsImage variant="treeOnTheRock" />
    </Wrapper>
  );
};

export default CleoLandingBenefits;

const Wrapper = styled.section`
  ${appLayoutPaddingsMixin};

  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  gap: ${({ theme }) => theme.spacing(15)};
  padding-bottom: ${({ theme }) => theme.spacing(18)};
  padding-top: ${({ theme }) => theme.spacing(18)};

  > * {
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    gap: ${({ theme }) => theme.spacing(10)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    gap: ${({ theme }) => theme.spacing(10)};
    flex-direction: row;
    padding-bottom: ${({ theme }) => theme.spacing(26)};

    > * {
      width: 50%;
    }
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    padding-bottom: ${({ theme }) => theme.spacing(18)};
  }
`;

const Content = styled.div``;

const Text = styled(Typography)`
  display: block;
  margin: ${({ theme }) => theme.spacing(5, 0, 6)};
  max-width: 609px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    max-width: unset;
  }
`;
Text.defaultProps = {
  variant: "paragraphSecondary"
};

const ContactUsButton = styled(Button)`
  min-width: 165px;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    width: 100%;
  }
`;

const BenefitsImage = styled(CleoLandingImage)`
  max-width: 768px;
`;
